import React from "react"
import { Link } from "gatsby"
import "./Message.scss"

export default function SuccessMessage() {
  return (
    <div className="container">
      <div className="row text-center text-light p-5 justify-content-center">
        <h2 className="text-light col-12">Thank you for your interest!</h2>
        <p className="text-muted col-12">I will contact you shortly.</p>
        <Link className="btn btn-primary text-light text-center" to="/">
          Back to Home
        </Link>
      </div>
    </div>
  )
}
