import React from "react"
import SEO from "../components/seo"
import SuccessMessage from "../components/Messages/SuccessMessage"

const SuccessPage = () => (
  <>
    <SEO title="Matyas Hofecker" />
    <SuccessMessage />
  </>
)

export default SuccessPage
